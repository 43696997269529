import React, { useState } from 'react';

const MultiPropertyOwnerForm = ({ className, language }) => {
  // States for form inputs
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [portfolioType, setPortfolioType] = useState('');
  const [isAvailable, setIsAvailable] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);

  // State for form validation errors
  const [error, setError] = useState('');

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic
    if (!firstName || !lastName || !email || !phone || !portfolioType || !acceptPrivacy) {
      setError('Please fill in all fields and accept the Privacy Policy.');
      return; // Prevent form submission if validation fails
    }

    // Clear the error message if all fields are valid
    setError('');

    // Handle form submission (e.g., send data to backend or process the form)
    console.log({
      firstName,
      lastName,
      email,
      phone,
      portfolioType,
      isAvailable,
      acceptPrivacy
    });
  };

  return (
    <div className={`px-8 py-12 md:py-16 md:px-20 bg-white relative border border-red-500`}>

      <form
        id="multi-property-owner-form"  // Form ID
        onSubmit={handleSubmit}
        className={`multi-property-owner-form ${className}`}
      >
        {error && <div className="text-red-500 mb-4">{error}</div>}  {/* Display error message */}


        <div class="relative">
          <div className="flex flex-col justify-center group !border-error col-span-12">
            <label htmlFor="firstName" className="text-sm pointer-events-none absolute top-1/2 duration-200 ease-in-out transform transition-transform -translate-y-1/2 group-focus-within:-translate-y-10 group-hover:-translate-y-10 false text-navy  ">Your First Name</label>
            <input
              id="firstName"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="bg-transparent border-b outline-none group-hover:border-opacity-100 focus:border-opacity-100 transition-colors duration-200 ease-in-out py-3.5 w-full border-b-navy border-opacity-30 disabled:text-opacity-30"
              required
            />
          </div>
        </div>

        <div class="relative">

          <div className="flex flex-col justify-center group !border-error col-span-12">
            <label htmlFor="lastName" className="text-sm pointer-events-none absolute top-1/2 duration-200 ease-in-out transform transition-transform -translate-y-1/2 group-focus-within:-translate-y-10 group-hover:-translate-y-10 false text-navy  ">Your Last Name</label>
            <input
              id="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="bg-transparent border-b outline-none group-hover:border-opacity-100 focus:border-opacity-100 transition-colors duration-200 ease-in-out py-3.5 w-full border-b-navy border-opacity-30 disabled:text-opacity-30"
              required
            />
          </div>
        </div>

        <div class="relative">

          <div className="flex flex-col justify-center group !border-error col-span-12">
            <label htmlFor="email" className="text-sm pointer-events-none absolute top-1/2 duration-200 ease-in-out transform transition-transform -translate-y-1/2 group-focus-within:-translate-y-10 group-hover:-translate-y-10 false text-navy  ">Your Email Address</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-transparent border-b outline-none group-hover:border-opacity-100 focus:border-opacity-100 transition-colors duration-200 ease-in-out py-3.5 w-full border-b-navy border-opacity-30 disabled:text-opacity-30"
              required
            />
          </div>
        </div>

        <div class="relative">

          <div className="flex flex-col justify-center group !border-error col-span-12">
            <label htmlFor="phone" className="text-sm pointer-events-none absolute top-1/2 duration-200 ease-in-out transform transition-transform -translate-y-1/2 group-focus-within:-translate-y-10 group-hover:-translate-y-10 false text-navy  ">Your Phone Number</label>
            <input
              id="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="bg-transparent border-b outline-none group-hover:border-opacity-100 focus:border-opacity-100 transition-colors duration-200 ease-in-out py-3.5 w-full border-b-navy border-opacity-30 disabled:text-opacity-30"
              required
            />
          </div>
        </div>

        <div className="flex flex-col justify-center group !border-error col-span-12">
          <label htmlFor="portfolioType" className="text-sm pointer-events-none absolute top-1/2 duration-200 ease-in-out transform transition-transform -translate-y-1/2 group-focus-within:-translate-y-10 group-hover:-translate-y-10 false text-navy  ">Portfolio Type</label>
          <select
            id="portfolioType"
            value={portfolioType}
            onChange={(e) => setPortfolioType(e.target.value)}
            className="bg-transparent border-b outline-none group-hover:border-opacity-100 focus:border-opacity-100 transition-colors duration-200 ease-in-out py-3.5 w-full border-b-navy border-opacity-30 disabled:text-opacity-30"
            required
          >
            <option value="">Select Portfolio Type</option>
            <option value="Buy-to-Let (BTL) Residential">Buy-to-Let (BTL) Residential</option>
            <option value="HMO">HMO</option>
            <option value="Student Accommodation">Student Accommodation</option>
            <option value="Serviced Accommodation">Serviced Accommodation</option>
            <option value="Mixed-Use">Mixed-Use</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="flex flex-col justify-center group !border-error col-span-12">
          <label className="inline-flex items-center text-white">
            <input
              type="checkbox"
              checked={isAvailable}
              onChange={(e) => setIsAvailable(e.target.checked)}
              className="mr-2"
            />
            Your property is available 365 days a year.
          </label>
        </div>

        <div className="flex flex-col justify-center group !border-error col-span-12">
          <label className="inline-flex items-center text-white">
            <input
              type="checkbox"
              checked={acceptPrivacy}
              onChange={(e) => setAcceptPrivacy(e.target.checked)}
              className="mr-2"
              required
            />
            By submitting this form, you accept our Privacy Policy.
          </label>
        </div>

        {/* <button
        type="submit"
        className="bg-blue-500 text-white p-3 rounded w-full hover:bg-blue-600"
      >
        test
      </button> */}



        <div class="col-span-12"><div class="w-full">
          <button class="btn btn--submit false w-full hover:cursor-default hover:bg-yellow" target="_self" disabled="">Request your market appraisal
          </button></div></div>


      </form>
    </div>
  );
};

export default MultiPropertyOwnerForm;
